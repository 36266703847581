<div class="p-2 flex gap-x-3 items-center cursor-pointer" [ngClass]="{ 'justify-center': isMenuColapsed() }" (click)="openLanguageModal()">
  <ds-icon
    [name]="DsIconEnum.Globe"
    [webfontWeight]="isChooseLanguageModalOpen() ? DsIconWeightEnum.Solid : DsIconWeightEnum.Regular"
    [webfontCustomClasses]="isMenuColapsed() ? 'text-gray-600 text-sm items-center' : 'text-gray-600 text-sm items-center justify-center'"></ds-icon>
  @if(!isMenuColapsed()){
  <span class="text-sm leading-5 font-medium text-gray-800">
    {{ currentLanguageName() }}
  </span>
  }
</div>

<ds-modal [id]="LanguageSwitcherModalEnum.ChooseLanguageModal" zIndex="z-50" paddingClasses="p-0" (backdropClose)="(false)" (onClose)="onLanguageModalClose()">
  <ds-choose-language-component [enableUserLocaleUpdate]="enableUserLocaleUpdate" *ngIf="isChooseLanguageModalOpen()"></ds-choose-language-component>
</ds-modal>
